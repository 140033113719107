<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p v-if="versionNumber.value === 1" class="mb-3">
        What is the specific heat capacity of a theoretical compound if it takes
        <stemble-latex content="$191~\text{J}$" /> to raise the temperature of a
        <number-value :value="mass" unit="\text{g}" /> sample of the compound by
        <number-value :value="tempIncrease1" unit="^\circ\text{C}?" />
      </p>

      <p v-else class="mb-3">
        A sample gives off <number-value :value="bombCalHeat" unit="\text{cal}" /> when burned in a
        bomb calorimeter. The temperature of the calorimeter assemble increased by
        <number-value :value="tempIncrease2" unit="^\circ\text{C.}" /> Calculate the heat capacity
        of the calorimeter in <stemble-latex content="$\text{kJ}~\text{K}^{-1}.$" />
      </p>

      <calculation-input
        v-if="versionNumber.value === 1"
        v-model="inputs.heatCapacity"
        prepend-text="$\text{Specific Heat Capacity}:$"
        append-text="$\text{J}~\text{g}^{-1}~^\degree\text{C}^{-1}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-else
        v-model="inputs.heatCapacity"
        prepend-text="$\text{Heat Capacity}:$"
        append-text="$\text{kJ}~\text{K}^{-1}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '@/tasks/components/NumberValue.vue';

export default {
  name: 'Question545',
  components: {
    NumberValue,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        heatCapacity: null,
      },
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content ?? 2;
    },
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
    tempIncrease1() {
      return this.taskState.getValueBySymbol('tempIncrease1').content;
    },
    bombCalHeat() {
      return this.taskState.getValueBySymbol('bombCalHeat').content;
    },
    tempIncrease2() {
      return this.taskState.getValueBySymbol('tempIncrease2').content;
    },
  },
};
</script>
